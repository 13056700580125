import Logo from "../../elements/Logo";
import Typography from "../../elements/Typography";
import * as S from "./CardStyled";

const Card = ({ title, showLogo = false, children, variant = 'h2' }) => {
  return (
    <>
      <S.Container>
        <S.BoxText>
          <Logo marginBottom={20} marginTop={30} />
          <Typography variant={variant}>{title}</Typography>
        </S.BoxText>
        {children}
      </S.Container>
    </>
  );
};

export default Card;
